@import url("https://fonts.googleapis.com/css?family=Open+Sans");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

/* Content Table CSS */
.table-header {
  background-color: white;
  text-align: left;
  padding: 20px !important;
}

.table-subheader {
  background-color: #f6f9fc;
  box-shadow: none !important;
  color: #292f36e8;
  border: none !important;
  text-align: left;
  padding: 10px 0 10px 20px !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  border-top: 1px solid#e9ecef;
  border-bottom: 1px solid#e9ecef;
}

.ReactTable .rt-thead.-headerGroups {
  border-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

.ReactTable .rt-tbody .rt-td {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 15px 15px 20px;
  background: white;
}

.ReactTable .rt-table {
  border-radius: 0.375rem !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px #efefef !important;
}

.ReactTable {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  border: none !important;
  border-radius: 0.375rem !important;
}
